import Swiper from 'swiper';

const ourValuesSlider  = new Swiper('.our-values-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  spaceBetween: 30,
  navigation:{
    prevEl: ".our-values-arrow-prev",
    nextEl: ".our-values-arrow-next",
  },
  pagination:{
    el: ".our-values-pagination",
    clickable: true,
  },
})
