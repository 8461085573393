const $inputField = $('.site-footer_top .gform_body input[type="email"]');

const updatePlaceholder = () => {
  if ($inputField.length) {
    $inputField.attr('placeholder', $(window).width() < 1200 ? 'Enter your email' : 'Enter your email address to receive news and updates from Shermco');
  }
};

// Initial check
updatePlaceholder();

// Update on window resize
$(window).resize(updatePlaceholder);
