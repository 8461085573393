import Swiper from 'swiper';

const sliderCards  = new Swiper('.cards-slider',{
  direction: 'horizontal',
  spaceBetween: 30,
  navigation:{
    nextEl: ".card-next",
    prevEl: ".card-prev",
  },
  breakpoints: {
    280: {
      slidesPerView: 1.5,
    },
    650: {
      slidesPerView: 2.5,
    },
    950: {
      slidesPerView: 3.5,
    },
    1400: {
      slidesPerView: 5,
    }
  }
})
