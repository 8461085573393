import Swiper from 'swiper';

const relatedProductsSwiper = new Swiper('.related-swiper', {
  navigation: {
    nextEl: '.related-next',
    prevEl: '.related-prev',
  },
  slidesPerView: 5,
  spaceBetween: 40,
  breakpoints: {
    280: {
      slidesPerView: 1.5,
    },
    650: {
      slidesPerView: 2.5,
    },
    950: {
      slidesPerView: 3.5,
    },
    1400: {
      slidesPerView: 5,
    }
  }
});
