import dialogPolyfill from 'dialog-polyfill';
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';

// Register the html dialog polyfill if it's not supported by the browser
if (typeof HTMLDialogElement !== 'function') {
    $('dialog').each(function () {
        dialogPolyfill.registerDialog(this);
    });
}

objectFitImages();
svg4everybody();
