import Swiper from 'swiper';
import {Navigation, Scrollbar, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Scrollbar, Navigation, EffectCreative, Autoplay]);

const datesSlider  = new Swiper('.dates_slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  speed: 800,
  // autoplay: {
  //   delay: 4000,
  // },
  navigation:{
    nextEl: ".dates-swiper-button-next",
    prevEl: ".dates-swiper-button-prev",
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: false,
  },
  on: {
    init: function () {
      $(".number-list_wrapper .dates_number-list__year").removeClass("active");
      $(".number-list_wrapper .dates_number-list__year").eq(0).addClass("active");
    },
    slideChangeTransitionStart: function () {
      $(".number-list_wrapper .dates_number-list__year").removeClass("active");
      $(".number-list_wrapper .dates_number-list__year").eq(datesSlider.realIndex).addClass("active");
    }
  }
});
$(".number-list_wrapper .dates_number-list__year").click(function () {
  datesSlider.slideTo($(this).index());
  $(".number-list_wrapper .dates_number-list__year").removeClass("active");
  $(this).addClass("active");
})
