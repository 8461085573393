$('.expand-all').on('click', function() {
  const $this = $(this);
  const $grid = $this.closest('.accordion-grid-wrapper');
  const $accordions = $grid.find('.faqs_details');

  if ($this.text() === 'Expand All') {
    $accordions.each(function() {
      $(this).attr('open', true);
    });
    $this.text('Close All');
  } else {
    $accordions.each(function() {
      $(this).removeAttr('open');
    });
    $this.text('Expand All');
  }
});
