$(document).ready(function() {
  const $solution = $('.solution');
  const $solutionLink = $('.solutions_link');
  const $mobileLink = $('.mobile_link');

  $solutionLink.click(function (e) {
    e.preventDefault();
    let tabId = $(this).data('solution');
    showSolution(tabId);
  });

  function showSolution(tabId) {
    if ($(window).width() > 800) {
      $solutionLink.removeClass('active-solution');
      $solution.hide();
      $(`#${tabId}`).show();
      $(`[data-solution="${tabId}"]`).addClass('active-solution');
    }
  }

  $mobileLink.click(function (e) {
    e.preventDefault();
    $(this).find('.solutions-mobile-content').slideToggle();
    if ($(window).width() < 801) {
      $(this).closest('.solutions_link').toggleClass('active-solution');
    }
  });

  $solution.hide();
  showSolution('solution0');
});
